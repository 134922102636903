import React, { FC, useCallback } from 'react';
import classNames from 'classnames';
import Nav from 'react-bootstrap/Nav';

import HeaderNavDropdownItem from 'gatsby-theme-dettol/src/components/Header/HeaderNavDropdownItem';
import DropdownLanguage from 'gatsby-theme-dettol/src/components/DropdownLanguage';
import { useBV } from 'gatsby-theme-dettol/src/hooks/useBV';

import { isBrowser } from 'gatsby-theme-dettol/src/utils/browser';
import { extractUrlFromMultiPicker } from 'gatsby-theme-dettol/src/utils/urlMultiPicker';
import { IHeaderNav, IMenuItemRendererProps } from 'gatsby-theme-dettol/src/components/Header/HeaderNav/models';
import 'gatsby-theme-dettol/src/components/Header/HeaderNav/HeaderNav.scss';

const MenuItemRenderer: FC<IMenuItemRendererProps> = ({ navItem, ...restProps }) => {
  const pathname = isBrowser() ? window.location.pathname : '';
  const BV = useBV();
  const extractedUrl = extractUrlFromMultiPicker(navItem.url);
  const isGenericReview = extractedUrl.includes('#GenericReview');
  const linkClick = useCallback(() => {
    restProps.handleRedirect();
    isGenericReview && BV.showProductPicker();
  }, []);

  if (navItem.panels) {
    return <HeaderNavDropdownItem {...restProps} navItem={navItem} />;
  }

  return (
    <a
      onFocus={() => restProps.showMenu(false)}
      href={`${isGenericReview ? '#reviews' : extractedUrl}`}
      className={classNames('nav-link', {
        active: pathname.includes(extractedUrl) && extractedUrl !== '/',
      })}
      onClick={linkClick}
    >
      {navItem.label}
    </a>
  );
};

const HeaderNav: FC<IHeaderNav> = ({
  menu,
  languageSelector: { title: langSelectorTitle, languages, currentLangCode, redirectBehavior },
  ...restProps
}) => {
  const navRight = menu?.navigation ? [...menu.navigation] : [];
  const navLeft = navRight.splice(0, Math.round(menu?.navigation.length / 2));

  return (
    <div className="dt-header__navbar-holder">
      <Nav>
        <div className="dt-header__nav-left">
          {navLeft.map(({ properties }) => (
            <MenuItemRenderer
              key={`HeaderNavDropdownItem-left-${properties.label}`}
              navItem={properties}
              {...restProps}
            />
          ))}
        </div>

        <div className="dt-header__nav-right">
          <div className="dt-header__nav-right-holder">
            {navRight.map(({ properties }) => (
              <MenuItemRenderer
                key={`HeaderNavDropdownItem-right-${properties.label}`}
                navItem={properties}
                {...restProps}
              />
            ))}
          </div>
        </div>
      </Nav>
      {languages ? (
        <DropdownLanguage
          title={langSelectorTitle}
          langs={languages}
          currentLangCode={currentLangCode}
          redirectToHome={redirectBehavior}
        />
      ) : null}
    </div>
  );
};

export default HeaderNav;

